@import url('https://fonts.googleapis.com/css?family=Lexend:300,400,500,600,700&display=swap');

.App-logo {
  height: 1em;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-rotate infinite 8s linear;
  }
}

.App-header {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App {
  background-color: #ffffff;
  display: flex;
  min-height: calc(100vh - 70px);
  flex-direction: column;
  padding: 2em 4em;
}

.App-footer {
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  height: 70px;
}

.App-body {
  margin-top: 20px;
  flex: 1 0 auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-rotate {
  0%,
  100% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-45deg);
  }
}
